import React, { useState } from "react";

import "./LoginForm2.css";
import ForgotPasswordPage from "./ForgotPasswordPage";
import { API_CONSTANT } from "../services/ApiConstant.js";
import { post } from "../services/ApiService.js";
import { ROUTE, SESSION_KEY, setInAsyncStorage } from "../utils/AppConstant.js";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

const LoginForm2 = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event?.preventDefault();
    setIsSubmitting(true);
    if (username === "" && password === "") {
    } else {
      event.preventDefault();
      const data = new FormData();
      data.append("email", username);
      data.append("password", password);
      post(`${API_CONSTANT?.login}`, data, false)
        .then((res) => {
          setWelcomeModal(true);
          setInAsyncStorage(SESSION_KEY.USER_DATA, res?.data?.data);
          setIsSubmitting(false);
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  if (showForgotPassword) {
    return (
      <ForgotPasswordPage
        onSendResetLink={() => setShowForgotPassword(false)}
      />
    );
  }

  return (
    <div>
      <div className="form-container">
        <img
          src="\image\Profile-Picture-removebg-preview.png"
          alt="Logo"
          className="logo"
        />
        <form>
          <Link to="/" className="left-arrow">
            {" "}
            {/* Link to OpenPage */}
            {/* <BsArrowLeft  className='left-arrow-icon'/> */}
            <h2>WEBI WORKSPACE</h2>
          </Link>
          <p>Please enter your details to Login.</p>
          <div className=" form-group">
            <input
              type="text"
              placeholder="Enter your username"
              className="!mb-1"
              value={username}
              onChange={handleUsernameChange}
            />
            {isSubmitting && username === "" && (
              <div className="text-sm text-red-500">UserName is required</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              className="!mb-1"
              value={password}
              onChange={handlePasswordChange}
            />
            {isSubmitting && password === "" && (
              <div className="text-sm text-red-500">Password is required</div>
            )}
          </div>
          <div className="!mb-2 forgot-password" onClick={handleForgotPassword}>
            Forgot Password?
          </div>
          <button onClick={handleSubmit} className="Login-button">
            Login
          </button>
          <p>
            Don't have an account?{" "}
            <a onClick={() => navigate(ROUTE?.REGISTER)}>Sign up</a>
          </p>
        </form>
      </div>
      <Transition appear show={welcomeModal}>
        <Dialog
          as="div"
          className="relative z-10 focus:outline-none"
          onClose={() => {
            setWelcomeModal(false);
            navigate(ROUTE?.LOGIN);
          }}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-white">
            <div className="flex items-center justify-center min-h-full p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-10 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-2xl p-10 bg-[#00AC24] rounded-xl backdrop-blur-4xl">
                  <p className="white px-10 text-[25px] font-bold text-white">
                    Welcome to the WebiDesigner Grow exploitational with our 360
                    Digital Services
                  </p>
                  <div className="flex justify-center">
                    {" "}
                    <button
                      onClick={() => {
                        setWelcomeModal(false);
                        navigate(ROUTE?.DASHBOARD);
                      }}
                      className="text-[#00AC24] bg-black px-6 py-4 text-[14px] rounded-lg"
                    >
                      Let's Grow
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default LoginForm2;
