import React, { useEffect, useState } from "react";
import "./Dashboard2.css";
import { Link, useNavigate } from "react-router-dom";
import { BiCodeBlock } from "react-icons/bi";
import { BsStar } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiMiniUsers } from "react-icons/hi2";
import { TbArrowWaveRightUp } from "react-icons/tb";
import { FcCheckmark } from "react-icons/fc";
import Header from "../../components/Header/Header";
import { API_CONSTANT } from "../../services/ApiConstant";
import { post } from "../../services/ApiService";
const Dashboard2 = () => {
  const [selectedMonth, setSelectedMonth] = useState("October");
  const navigate = useNavigate();
  const [showUserInfo, setShowUserInfo] = useState(false);
const [paymentList,setPaymentList]=useState([])
  const handleProfileClick = () => {
    setShowUserInfo(!showUserInfo);
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  useEffect(() => {
    getPaymentList();
  }, []);

  const getPaymentList = () => {
    post(API_CONSTANT?.paymentList)
      .then((res) => {
setPaymentList(res?.data?.data)
      })
      .catch((err) => console.log("error", err));
  };
  return (
    <div className="dashboard2-body">
      <div className="Dashboard-container">
        <Header title={"Dashboard"} />
        <div>
          {/* Boxes-1 */}
          <div className="box-container gap-5 px-6 ">
            <div className="box">
              <div className="Box-heading">
                <h1>Active work</h1>
                <div className="HiMiniUsers">
                  <HiMiniUsers />
                </div>
              </div>
              <p>100</p>
              <div className="Box-heading-two">
                <TbArrowWaveRightUp className="TbArrowWaveRightUp" />
                <p>
                  <span>8.5%</span> Up from yesterday
                </p>
              </div>
            </div>

            {/* Boxes-1 */}
            <div className="box">
              <div className="Box-heading">
                <h1>Total Pay out</h1>
                <div className="HiMiniUsers">
                  <HiMiniUsers />
                </div>
              </div>
              <p>$1000</p>
              <div className="Box-heading-two">
                <TbArrowWaveRightUp className="TbArrowWaveRightUp" />
                <p>
                  <span>8.5%</span> Up from yesterday
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Table with data */}
        <div className="table-container-Dashboard">
          <div className="top-headings">
            <h1>Deals Details</h1>
         
          </div>
          <table className="data-table-Dashboard">
            <thead>
              <tr>
                <th>Service Name</th>
                <th>Project Manager</th>
                <th>Planning</th>
                <th>Designing</th>
                <th>Review</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
{paymentList?.map((list)=>{
    return(
        <tr>
        <td className="img-table">
          <img src="\Bitmap.png" alt="" />
         {list?.name ?? "-"}
        </td>
        <td>{list?.assgin_id ?? "-"}</td>
        <td>

         {list?.plannig==="0" ?  <FcCheckmark className="FcCheckmark" /> : list?.plannig}
        </td>
        <td>
        {list?.desgining==="0" ?  <FcCheckmark className="FcCheckmark" /> : list?.desgining}
        </td>
        <td>
        {list?.review==="0" ?  <FcCheckmark className="FcCheckmark" /> : list?.review}
        </td>
        <td>
          <button className={`${list?.status==="Pending" ? 'Pending-btn': list?.status==="Complete" ? "Complete-btn" : "Rejected-btn"} table-btn !text-white`} >{list?.status}</button>
        </td>
      </tr>
    )
})}  
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard2;
