import React, { useState, useRef, useEffect } from 'react';
import './Notice.css';
import { IoShareOutline, IoChevronDownSharp, IoCalendarOutline } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import PopupSlider from './PopupSlider';
import { Link } from 'react-router-dom';

const Notice = ({ onLogout }) => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showPopupSlider, setShowPopupSlider] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [showCustomDateInputs, setShowCustomDateInputs] = useState(false);
  const [statusSearch, setStatusSearch] = useState('');
  const [typeSearch, setTypeSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('Select Type');
  const [selectedType, setSelectedType] = useState('Select Sub Type');
  const [tableData, setTableData] = useState([
    {
      id: 1,
      employee: "Samraddhi Gupta",
      type: "Informational",
      subType: "Leave",
      project: "hii",
      created: "2024-02-29"
    }
  ]);

  const statusList = [
    "Select Type",
    "Appreciation",
    "Notice",
  ];

  const typeList = [
    "Select Sub Type",
    "Project",
    "Leave"
  ];

  const statusButtonRef = useRef(null);
  const typeButtonRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const typeDropdownRef = useRef(null);

  useEffect(() => {
    if (statusButtonRef.current && statusDropdownRef.current) {
      statusDropdownRef.current.style.width = `${statusButtonRef.current.offsetWidth}px`;
    }
    if (typeButtonRef.current && typeDropdownRef.current) {
      typeDropdownRef.current.style.width = `${typeButtonRef.current.offsetWidth}px`;
    }

    // Event listener to close dropdowns on click outside
    const handleClickOutside = (event) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target) &&
        statusButtonRef.current &&
        !statusButtonRef.current.contains(event.target)
      ) {
        setShowStatusDropdown(false);
      }
      if (
        typeDropdownRef.current &&
        !typeDropdownRef.current.contains(event.target) &&
        typeButtonRef.current &&
        !typeButtonRef.current.contains(event.target)
      ) {
        setShowTypeDropdown(false);
      }
      if (
        showDateDropdown &&
        event.target.closest('.date-dropdown') === null
      ) {
        setShowDateDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showStatusDropdown, showTypeDropdown, showDateDropdown]);

  const handleProfileClick = () => {
    setShowUserInfo(!showUserInfo);
  };

  const handleLogout = () => {
    onLogout();
  };

  const toggleStatusDropdown = () => {
    setShowStatusDropdown(!showStatusDropdown);
    setShowTypeDropdown(false);
    setShowCustomDateInputs(false);
  };

  const toggleTypeDropdown = () => {
    setShowTypeDropdown(!showTypeDropdown);
    setShowStatusDropdown(false);
    setShowCustomDateInputs(false);
  };

  const toggleDateDropdown = () => {
    setShowDateDropdown(!showDateDropdown);
    setShowCustomDateInputs(false);
  };

  const handleStatusSearch = (event) => {
    setStatusSearch(event.target.value);
  };

  const handleTypeSearch = (event) => {
    setTypeSearch(event.target.value);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setShowStatusDropdown(false);
    if (status === "Appreciation") {
      setTableData([
        {
          id: 1,
          employee: "John Doe",
          type: "Appreciation",
          subType: "Project",
          project: "New Project",
          created: "2024-03-15"
        },
        {
          id: 2,
          employee: "Jane Smith",
          type: "Appreciation",
          subType: "Project",
          project: "Completed Task",
          created: "2024-03-20"
        }
      ]);
    } else if (status === "Notice") {
      setTableData([
        {
          id: 1,
          employee: "Alice Johnson",
          type: "Notice",
          subType: "Leave",
          project: "Upcoming Leave",
          created: "2024-04-01"
        }
      ]);
    }
  };

  const handleTypeClick = (type) => {
    setSelectedType(type);
    setShowTypeDropdown(false);
    if (type === "Project") {
      setTableData([
        {
          id: 1,
          employee: "John Doe",
          type: "Appreciation",
          subType: "Project",
          project: "New Project",
          created: "2024-03-15"
        },
        {
          id: 2,
          employee: "Jane Smith",
          type: "Appreciation",
          subType: "Project",
          project: "Completed Task",
          created: "2024-03-20"
        }
      ]);
    } else if (type === "Leave") {
      setTableData([
        {
          id: 1,
          employee: "Alice Johnson",
          type: "Notice",
          subType: "Leave",
          project: "Upcoming Leave",
          created: "2024-04-01"
        }
      ]);
    }
  };

  const handleDateClick = (dateOption) => {
    if (dateOption === "Custom Date") {
      setShowCustomDateInputs(true);
      setShowDateDropdown(false);
    } else {
      console.log("Selected date option:", dateOption);
    }
  };

  const handleApplyCustomDate = () => {
    console.log("Custom Date Range Applied");
    setShowCustomDateInputs(false);
  };

  const handleEyeIconClick = (row) => {
    setSelectedRow(row);
    setShowPopupSlider(true);
  };

  const handleClosePopupSlider = () => {
    setShowPopupSlider(false);
  };

  return (
    <div className='notice-body'>
      <div className="notice-heading">
        <h1>Notice and Appreciation Details</h1>
        <div className="profile-container" onClick={handleProfileClick}>
          <img src=".\image\Group 15.png" alt="Profile" className="profile-image" />
          {showUserInfo && (
            <div className="dropdown-menu">
              <div className="user-info">
                <Link to="/ProfilePage">
                  <div className="user-info-content">
                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image-small" />
                    <div>
                      <p className="user-name">Samraddhi Gupta</p>
                      <p className="user-subtitle">Software Engineer</p>
                    </div>
                  </div>
                </Link>
              </div>
              <hr className="separator" />
              <div className="logout" onClick={handleLogout}>
                <IoShareOutline size={15} style={{ marginRight: '5px' }} />
                <p>Log Out</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="Notice-search-frame">
        <div className="search-status-type">
          <div className="dropdown-container" style={{ position: 'relative' }}>
            <button
              ref={statusButtonRef}
              className="status-button"
              onClick={toggleStatusDropdown}
            >
              {selectedStatus} <IoChevronDownSharp />
            </button>
            {showStatusDropdown && (
              <div
                ref={statusDropdownRef}
                className="status-dropdown-2 dropdown-menu"
                style={{ position: 'absolute', top: '100%', width: statusButtonRef.current ? statusButtonRef.current.offsetWidth : 'auto' }}
              >
                <input
                  type="text"
                  placeholder="Search Status..."
                  value={statusSearch}
                  onChange={handleStatusSearch}
                  className="dropdown-search-input-1"
                />
                <ul>
                  {statusList.map((item, index) => (
                    <li key={index} onClick={() => handleStatusClick(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="dropdown-container" style={{ position: 'relative' }}>
            <button
              ref={typeButtonRef}
              className="type-button"
              onClick={toggleTypeDropdown}
            >
              {selectedType} <IoChevronDownSharp />
            </button>
            {showTypeDropdown && (
              <div
                ref={typeDropdownRef}
                className="type-dropdown-2 dropdown-menu"
                style={{ position: 'absolute', top: '100%', width: typeButtonRef.current ? typeButtonRef.current.offsetWidth : 'auto' }}
              >
                <input
                  type="text"
                  placeholder="Search Type..."
                  value={typeSearch}
                  onChange={handleTypeSearch}
                  className="dropdown-search-input"
                />
                <ul>
                  {typeList.map((item, index) => (
                    <li key={index} onClick={() => handleTypeClick(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="date-dropdown">
            <div className="date-dropdown-icon" onClick={toggleDateDropdown}>
              <IoCalendarOutline size={20} className="date-icon" />
              <IoChevronDownSharp size={20} className={`angle-down-icon ${showDateDropdown ? "rotate" : ""}`} />
            </div>
            {showDateDropdown && (
              <div className="date-dropdown-content-notice dropdown-menu left-aligned">
                <ul>
                  <li onClick={() => handleDateClick("Today")}>Today</li>
                  <li onClick={() => handleDateClick("This Week")}>This Week</li>
                  <li onClick={() => handleDateClick("This Month")}>This Month</li>
                  <li onClick={() => handleDateClick("Custom Date")}>Custom Date</li>
                </ul>
              </div>
            )}

            {showCustomDateInputs && (
              <div className="custom-date-options">
                <input type="date" className="date-input" />
                <input type="date" className="date-input" />
                <button className="apply-button" onClick={handleApplyCustomDate}>Filter</button>
              </div>
            )}
          </div>
        </div>
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>No</th>
                <th>EMPLOYEE</th>
                <th>TYPE</th>
                <th>SUB TYPE</th>
                <th>PROJECT</th>
                <th>CREATED</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.employee}</td>
                  <td>{row.type}</td>
                  <td>{row.subType}</td>
                  <td>{row.project}</td>
                  <td>{row.created}</td>
                  <td>
                    <a href="#" onClick={() => handleEyeIconClick(row)}>
                      <FaRegEye />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showPopupSlider && <PopupSlider row={selectedRow} onClose={handleClosePopupSlider} />}
    </div>
  );
};

export default Notice;
