import React, { useEffect, useState } from "react";
import LoginForm2 from "../../LoginForm/LoginForm2";
import { SESSION_KEY, getFromAsync } from "../../utils/AppConstant";
import OpenPage from "../../LoginForm/Openpage";
import usePersistState from "../../hook/usePersistState";

const Login = () => {
  const [userType] = usePersistState("", SESSION_KEY.USER_TYPE);
  return (
    <div>
      {userType !== "" && userType !== null ? <LoginForm2 /> : <OpenPage />}
    </div>
  );
};
export default Login;
