import React, { useState, useEffect } from 'react';
import './Addtimesheet.css'; 
import { IoShareOutline } from "react-icons/io5";
import { LiaAngleDownSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Link } from 'react-router-dom';

const AddTimesheet = ({ onLogout }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [showDateDropdown, setShowDateDropdown] = useState(false);
    const [switchValue, setSwitchValue] = useState(false);

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        // Call the onLogout function passed as a prop
        onLogout();
    };

    // State to manage dropdown visibility for each button
    const [showProjectTypeDropdown, setShowProjectTypeDropdown] = useState(false);
    const [showProjectDropdown, setShowProjectDropdown] = useState(false);
    const [showTimesheetTypeDropdown, setShowTimesheetTypeDropdown] = useState(false);

    const toggleProjectTypeDropdown = () => {
        setShowProjectTypeDropdown(!showProjectTypeDropdown);
        setShowProjectDropdown(false);
        setShowTimesheetTypeDropdown(false);
        setShowDateDropdown(false);
    };

    const toggleProjectDropdown = () => {
        setShowProjectDropdown(!showProjectDropdown);
        setShowProjectTypeDropdown(false);
        setShowTimesheetTypeDropdown(false);
        setShowDateDropdown(false);
    };

    const toggleTimesheetTypeDropdown = () => {
        setShowTimesheetTypeDropdown(!showTimesheetTypeDropdown);
        setShowProjectTypeDropdown(false);
        setShowProjectDropdown(false);
        setShowDateDropdown(false);
    };

    const toggleDateDropdown = () => {
        setShowDateDropdown(!showDateDropdown);
        setShowProjectTypeDropdown(false);
        setShowProjectDropdown(false);
        setShowTimesheetTypeDropdown(false);
    };

    const handleSwitchChange = () => {
        setSwitchValue(!switchValue);
    };

    // State for selected button
    const [selectedButton, setSelectedButton] = useState('');

    // Table headings based on the selected button
    let tableHeadings = [];
    let tableData = [];

    if (selectedButton === 'Pending') {
        tableHeadings = ['PROJECT', 'TYPE', 'TASK', 'EMPLOYEE', 'DATE', 'TIME', 'ACTION'];
        tableData = [
            ['1', '2024-04-01', 'Project A', '8', 'Pending', 'Edit/Delete', 'View Details'],
            ['2', '2024-04-02', 'Project B', '6', 'Pending', 'Edit/Delete', 'View Details'],
            ['3', '2024-04-03', 'Project C', '7', 'Pending', 'Edit/Delete', 'View Details'],
        ];
    } else if (selectedButton === 'Approved') {
        tableHeadings = ['ID', 'Date', 'Project', 'Hours', 'Approver', 'Approved Date', 'Actions'];
        tableData = [
            ['1', '2024-04-01', 'Project A', '8', 'John Doe', '2024-04-02', 'Edit/Delete'],
            ['2', '2024-04-02', 'Project B', '6', 'Jane Smith', '2024-04-03', 'Edit/Delete'],
            ['3', '2024-04-03', 'Project C', '7', 'Alex Johnson', '2024-04-04', 'Edit/Delete'],
        ];
    } else if (selectedButton === 'Rejected') {
        tableHeadings = ['ID', 'Date', 'Project', 'Hours', 'Reason', 'Actions', 'Details'];
        tableData = [
            ['1', '2024-04-01', 'Project A', '8', 'Incomplete', 'Edit/Delete', 'View Details'],
            ['2', '2024-04-02', 'Project B', '6', 'Incorrect Data', 'Edit/Delete', 'View Details'],
            ['3', '2024-04-03', 'Project C', '7', 'Missing Details', 'Edit/Delete', 'View Details'],
        ];
    }

    // Function to handle button clicks and update selected button state
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        localStorage.setItem('selectedButton', buttonName); // Store selected button in local storage
    };

    // Use navigate hook for routing
    const navigate = useNavigate();

    // Function to handle navigation to Timesheet component
    const handleAddTimesheet = () => {
        navigate('/Timesheet');
    };

    // Effect to set the selected button from local storage on component mount
    useEffect(() => {
        const storedSelectedButton = localStorage.getItem('selectedButton');
        if (storedSelectedButton) {
            setSelectedButton(storedSelectedButton);
        }
    }, []);

    return (
        <div className='add-timesheet-body'>
            <div className="add-timesheet-heading">
                <h1>Add Timesheet</h1>
                <div className="profile-container" onClick={handleProfileClick}>
                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image" />
                    {showUserInfo && (
                        <div className="dropdown-menu">
                            <div className="user-info">
                            <Link to="/ProfilePage"> {/* Link to ProfilePage */}
                                <div className="user-info-content">
                                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image-small" />
                                    <div>
                                        <p className="user-name">Samraddhi Gupta</p>
                                        <p className="user-subtitle">Software Engineer</p>
                                    </div>
                                    </div>
                                    </Link>
                            </div>
                            <hr className="separator" />
                            <div className="logout" onClick={handleLogout}>
                                <IoShareOutline size={15} style={{ marginRight: '5px' }} />
                                <p>Log Out</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="add-timesheet-form">
                <div className="dropdown-container">
                    <button className="form-button" onClick={toggleProjectTypeDropdown}>
                        Select Project Type <LiaAngleDownSolid className="down-icon"/>
                    </button>
                    <div className={`dropdown-content project-type-dropdown ${showProjectTypeDropdown ? 'active' : ''}`}>
                        {/* Dropdown content for Project Type */}
                        <ul>
                            <li>PROJECT</li>
                            <li>Project Type 2</li>
                            <li>Project Type 3</li>
                        </ul>
                    </div>
                </div>

                <div className="dropdown-container">
                    <button className="form-button" onClick={toggleProjectDropdown}>
                        Select Project <LiaAngleDownSolid className="down-icon"/>
                    </button>
                    <div className={`dropdown-content project-dropdown ${showProjectDropdown ? 'active' : ''}`}>
                        {/* Dropdown content for Project */}
                        <ul>
                            <li>Project 1</li>
                            <li>Project 2</li>
                            <li>Project 3</li>
                        </ul>
                    </div>
                </div>

                <div className="dropdown-container">
                    <button className="form-button" onClick={toggleTimesheetTypeDropdown}>
                        Select timesheet type <LiaAngleDownSolid className="down-icon" />
                    </button>
                    <div className={`dropdown-content timesheet-type-dropdown ${showTimesheetTypeDropdown ? 'active' : ''}`}>
                        {/* Dropdown content for Timesheet Type */}
                        <ul>
                            <li>Timesheet Type 1</li>
                            <li>Timesheet Type 2</li>
                            <li>Timesheet Type 3</li>
                        </ul>
                    </div>
                </div>

                {/* Add Timesheet button with onClick handler */}
                <button className="add-timesheet-button" onClick={handleAddTimesheet}>+ Add Timesheet</button>
            </div>

            <div className="section-box-2">
                <div className="new-section">
                    <button className={`new-button ${selectedButton === 'Pending' ? 'active' : ''}`} onClick={() => handleButtonClick('Pending')}>Pending(6)</button>
                    <button className={`new-button ${selectedButton === 'Approved' ? 'active' : ''}`} onClick={() => handleButtonClick('Approved')}>Approved(241)</button>
                    <button className={`new-button ${selectedButton === 'Rejected' ? 'active' : ''}`} onClick={() => handleButtonClick('Rejected')}>Rejected(0)</button>
                                    
                    <div className="switch-container">
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider"></span>
                        </label>
                        <label className="switch-label">Show timesheet description</label>
                    </div>
                </div>

                {/* Table to display timesheet data */}
                <div className="timesheet-table-container">
                    {selectedButton && (
                        <table className="timesheet-table">
                            <thead>
                                <tr>
                                    {tableHeadings.map((heading, index) => (
                                        <th key={index}>{heading}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((data, dataIndex) => (
                                            <td key={dataIndex}>{data}</td>
                                        ))}
                                    </tr>
                                ))}
                                
                                <td colSpan={tableHeadings.length} style={{ textAlign: 'right' }}>
                                    Total : <span style={{ marginRight: '350px' }}>11:15:00 </span>
                                </td>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddTimesheet;
