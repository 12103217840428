import React, { useEffect, useState } from "react";
import "./Services.css";
import { Link, Route, useNavigate } from "react-router-dom";
import { FcSpeaker } from "react-icons/fc";
import { BiCodeBlock } from "react-icons/bi";
import { BsStar } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { API_CONSTANT } from "../../services/ApiConstant";
import { get } from "../../services/ApiService";
import { toast } from "react-toastify";
import { ROUTE, ganrateUrl } from "../../utils/AppConstant";
import Header from "../../components/Header/Header";

const Services = ({ onLogout }) => {
  const navigate = useNavigate();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const handleProfileClick = () => {
    setShowUserInfo(!showUserInfo);
  };

  const handleLogout = () => {
    onLogout();
    navigate("/login");
  };
  const getService = () => {
    get(API_CONSTANT.category)
      .then((res) => {
        console.log("data", res);
        setCategoryList(res?.data?.data);
      })
      .catch((e) => {
        toast.error(e?.message || e?.data?.ErrorMessage || String(e));
      });
  };
  useEffect(() => {
    getService();
  }, []);
  return (
    <div className="dashboard2-body">
      <div className="Dashboard-container">
      <Header title={"Services"} />
      </div>
      {/* first section */}

      <div className="main-section-container">
        <h1 className="Choose-Headings">Choose what best Suit for you!! </h1>
        <div className="flex gap-8 flex-wrap justify-evenly ">
          {categoryList?.length !== 0
            ? categoryList?.map((list) => {
                return (
                  <div
                    onClick={() => {
                      navigate(`${ROUTE?.SERVICE}/${list?.id}`);
                    }}
                    className="Serive-box "
                  >
                    <div className="Serive-content">
                      <div className="Headings-Boxs w-[280px] break-words truncate">
                        <h1>{list?.name}</h1>

                        <div className="image-box">
                          <p>View entire list</p>
                          <img
                            src={
                              list?.image
                                ? list?.image
                                : "/Service-image/Teacher explaining while sitting on armchair.png"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="Dot-box">
                        <div className="Box-circle"></div>
                        <div className="Box-circle"></div>
                        <div className="Box-circle"></div>
                      </div>
                    </div>
                  </div>
                );
              })
            : "No service available"}
        </div>
      </div>
    </div>
  );
};

export default Services;
