import React from 'react';
import './PopupSlider.css';

const PopupSlider = ({ onClose, row }) => {
  // Default values if row is undefined
  const defaultRow = {
    employee: "Unknown",
    project: "Unknown",
    description: "No description available",
    created: "Unknown",
    createdBy: "Unknown",
    type: "Unknown",
    subType: "Unknown"
  };

  const rowData = row || defaultRow;

  return (
    <div className="popup-slider-overlay">
      <div className="popup-slider">
        <div className="popup-header">
          <h3>Notices and Appreciation Details</h3> {/* Updated heading */}
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-content">
          {rowData && (
            <>
              <p><strong>Project:</strong> {rowData.project}</p>
              <p><strong>Description:</strong> {rowData.description}</p>
              <p><strong>Created:</strong> {rowData.created}</p>
              <p><strong>Created By:</strong> {rowData.createdBy}</p>
              <p><strong>Type:</strong> {rowData.type}</p>
              <p><strong>Sub Type:</strong> {rowData.subType}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupSlider;
