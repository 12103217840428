import axios from "axios";
import { SESSION_KEY, getFromAsync } from "../utils/AppConstant";

const API = axios.create({
  baseURL: "https://admin.webidesigners.com/api",
});

const headerWithToken = () => {
  const token = JSON.parse(
    localStorage.getItem(SESSION_KEY.USER_DATA) || "{}"
  ).token;
  return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
};

const get = async (endPoint) => {
  const header = headerWithToken();
  return API.get(endPoint, header);
};

const post = async (endPoint, data) => {
  const header = headerWithToken();
  return API.post(endPoint, data, header);
};
// console.log("process.env.NEXT_SERVER_URL",process.env.NEXT_SERVER_URL);

export { get, post };
