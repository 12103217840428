import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineDashboard } from "react-icons/md";
import { BiMenuAltLeft } from "react-icons/bi";
import { MdOutlineNoteAlt } from "react-icons/md";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { IoMdNotifications } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import { LiaAngleRightSolid } from "react-icons/lia";
import { FaDropbox, FaCalendarAlt } from "react-icons/fa";
import { FaCircleDot } from "react-icons/fa6";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { IoBookOutline } from "react-icons/io5";

const SideBar = ({ children, isLoggedIn, userType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  const handleDropdownToggle = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const employeeRoutes = [
    {
      path: "/",
      name: "Dashboard",
      icon: <MdOutlineDashboard />,
    },
    {
      path: "/Project",
      name: "Project",
      icon: <FaDropbox />,
    },
    {
      path: "/Calendar",
      name: "Calendar",
      icon: <FaCalendarAlt />,
    },
    {
      path: "",
      name: "Timesheet",
      icon: <MdOutlineNoteAlt />,
      rightIcon: <LiaAngleRightSolid />,
      subItems: [
        {
          path: "/Timesheet",
          name: "View Timesheet",
          icon: <FaCircleDot />,
        },
        {
          path: "/AddTimesheet",
          name: "Add Timesheet",
          icon: <FaCircleDot />,
        },
      ],
    },
    {
      path: "",
      name: "Leave Management",
      icon: <BiSolidPlaneAlt />,
      rightIcon: <LiaAngleRightSolid />,
      subItems: [
        {
          path: "/Applyleave",
          name: "Apply Leave",
          icon: <FaCircleDot />,
        },
        {
          path: "/Leave",
          name: "Leave Details",
          icon: <FaCircleDot />,
        },
      ],
    },
    {
      path: "/Announcements",
      name: "Announcements",
      icon: <IoMdNotifications />,
    },
    {
      path: "/Notice",
      name: "Notice and Appreciation",
      icon: <RiErrorWarningFill />,
    },
  ];

  const adminRoutes = [
    {
      path: "/Dashboard2",
      name: "Dashbord",
      icon: <MdOutlineDashboard />,
    },
    {
      path: "/services",
      name: "Services",
      icon: <MdOutlineMiscellaneousServices />,
    },
    {
      path: "/Request",
      name: "Request",
      icon: <IoBookOutline />,
    },
  ];

  const routes =
    isLoggedIn && userType === "employee" ? employeeRoutes : adminRoutes;

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            minWidth: isOpen ? "350px" : "81px",
            width: isOpen ? "350px" : "81px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.img
                  className="img-logo"
                  src="\image\Profile-Picture-removebg-preview.png"
                  alt="Logo"
                />
              )}
            </AnimatePresence>

            <div className="bars" style={{ width: isOpen ? "40px" : "55px" }}>
              <BiMenuAltLeft onClick={toggle} style={{ fontSize: "25px" }} />
            </div>
          </div>
          <section className="routes">
            {routes.map((route, index) => (
              <motion.div key={index}>
                {route.subItems ? (
                  <div onClick={() => handleDropdownToggle(index)}>
                    <div className="link" activeClassName="active">
                      <div className="icon" style={{ fontSize: "25px" }}>
                        {route.icon}
                      </div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            className="link_text"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            {route.name}
                            {route.rightIcon}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                    <AnimatePresence>
                      {activeDropdown === index && isOpen && (
                        <motion.div
                          className="sub-items"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {route.subItems.map((subItem, idx) => (
                            <NavLink
                              key={idx}
                              to={subItem.path}
                              className="sub-item"
                              activeClassName="active"
                              onClick={(e) => e.stopPropagation()} // Prevent closing the dropdown
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginRight: "8px" }}>
                                  {subItem.icon}
                                </span>
                                {subItem.name}
                              </div>
                            </NavLink>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <NavLink
                    to={route.path}
                    className="link"
                    activeClassName="active"
                  >
                    <div className="icon" style={{ fontSize: "25px" }}>
                      {route.icon}
                    </div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          className="link_text"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {route.name}
                          {route.rightIcon}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                )}
              </motion.div>
            ))}
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
