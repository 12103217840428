import React, { useState, useEffect, useRef } from 'react';
import './Project.css'; 
import { IoShareOutline, IoSearch, IoChevronDownSharp } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { Link } from 'react-router-dom';



const Project = ({ onLogout }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [showTypeDropdown, setShowTypeDropdown] = useState(false);
    const [statusSearch, setStatusSearch] = useState('');
    const [typeSearch, setTypeSearch] = useState('');

    const statusRef = useRef(null);
    const typeRef = useRef(null);

    useEffect(() => {
        const handleClickOutsideStatus = (event) => {
            if (statusRef.current && !statusRef.current.contains(event.target)) {
                setShowStatusDropdown(false);
            }
        };

        const handleClickOutsideType = (event) => {
            if (typeRef.current && !typeRef.current.contains(event.target)) {
                setShowTypeDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutsideStatus);
        document.addEventListener("mousedown", handleClickOutsideType);

  
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideStatus);
            document.removeEventListener("mousedown", handleClickOutsideType);
        };
    }, []);

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        onLogout();
    };

    const toggleStatusDropdown = () => {
        setShowStatusDropdown(!showStatusDropdown);
    };

    const toggleTypeDropdown = () => {
        setShowTypeDropdown(!showTypeDropdown);
    };

    const handleStatusSearch = (e) => {
        setStatusSearch(e.target.value);
    };

    const handleTypeSearch = (e) => {
        setTypeSearch(e.target.value);
    };

    const handleReset = () => {
        window.location.reload();
    };

    return (
        <div className='project-body'>
            <div className="project-heading">
                <h1>All Project</h1>
                <div className="profile-container" onClick={handleProfileClick}>
                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image" />
                    {showUserInfo && (
                        <div className="dropdown-menu">
                            <div className="user-info">
                                <Link to="/ProfilePage">
                                    <div className="user-info-content">
                                        <img src=".\image\Group 15.png" alt="Profile" className="profile-image-small" />
                                        <div>
                                            <p className="user-name">Samraddhi Gupta</p>
                                            <p className="user-subtitle">Software Engineer</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <hr className="separator" />
                            <div className="logout" onClick={handleLogout}>
                                <IoShareOutline size={15} style={{ marginRight: '5px' }} />
                                <p>Log Out</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="search-frame">
                <div className="search-status-type">
                    <div className="search-container">
                        <input type="text" placeholder="Search..." className="search-input" />
                        <div className="icon-search-wrapper">
                            <IoSearch className="icon-search" />
                        </div>
                    </div>
                    <div className="status-button-container">
                        <button className="status-button" onClick={toggleStatusDropdown}>
                            Project Status <IoChevronDownSharp />
                        </button>
                        <div ref={statusRef} className={showStatusDropdown ? "dropdown-project show" : "dropdown-project"}>
                            <input
                                type="text"
                                placeholder="Search Status"
                                value={statusSearch}
                                onChange={handleStatusSearch}
                                className="dropdown-search-input"
                            />
                            {/* List of Status Items */}
                            <ul>
                                <li>Status 1</li>
                                <li>Status 2</li>
                                <li>Status 3</li>
                                {/* Add more status items */}
                            </ul>
                        </div>
                    </div>
                    <div className="type-button-container">
                        <button className="type-button" onClick={toggleTypeDropdown}>
                            Project Type <IoChevronDownSharp />
                        </button>
                        <div ref={typeRef} className={showTypeDropdown ? "dropdown-project show" : "dropdown-project"}>
                            <input
                                type="text"
                                placeholder="Search Type"
                                value={typeSearch}
                                onChange={handleTypeSearch}
                                className="dropdown-search-input"
                            />
                            {/* List of Type Items */}
                            <ul>
                                <li>Type 1</li>
                                <li>Type 2</li>
                                <li>Type 3</li>
                                {/* Add more type items */}
                            </ul>
                        </div>
                    </div>
                    <button className="button-reset" onClick={handleReset}>Reset</button>
                </div>
            </div>

            {/* Table with data */}
            <div className="table-container-project">
                <table className="data-table-project">
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>PTA/PM</th>
                            <th>CLIENT</th>
                            <th>STATUS</th>
                            <th>TYPE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1 R&D</td>
                            <td>Vaibhavi</td>
                            <td>IPS Internal</td>
                            <td>In progress</td>
                            <td>T & M</td>
                            <td>
                                <a href="#" onClick={() => console.log("Icon Clicked!")}>
                                    <FaRegEye />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>2 R&D</td>
                            <td>Vaibhavi</td>
                            <td>IPS Internal</td>
                            <td>In progress</td>
                            <td>T & M</td>
                            <td>
                                <a href="#" onClick={() => console.log("Icon Clicked!")}>
                                    <FaRegEye />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>3 R&D</td>
                            <td>Vaibhavi</td>
                            <td>IPS Internal</td>
                            <td>In progress</td>
                            <td>T & M</td>
                            <td>
                                <a href="#" onClick={() => console.log("Icon Clicked!")}>
                                    <FaRegEye />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>4 R&D</td>
                            <td>Vaibhavi</td>
                            <td>IPS Internal</td>
                            <td>In progress</td>
                            <td>T & M</td>
                            <td>
                                <a href="#" onClick={() => console.log("Icon Clicked!")}>
                                    <FaRegEye />
                                </a>
                            </td>
                        </tr>
                        {/* Add more rows as needed */}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Project;
