import React, { useState } from 'react';
import './Dashboard.css'; 
import { Link, useNavigate } from 'react-router-dom'; 
import { IoShareOutline } from "react-icons/io5";
import PopupSlider from './PopupSlider'; 
import Header from '../../components/Header/Header';

const Dashboard = ({ onLogout }) => {
    const navigate = useNavigate(); 
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [showPopup, setShowPopup] = useState(false); 
    const [popupData, setPopupData] = useState(null); 

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        onLogout();
        navigate('/login');
    };

    const handlePopupOpen = (data) => {
        setPopupData(data);
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const popupData1 = {
        project: "Project 1",
        description: "Description for Project 1",
        created: "April 12, 2024",
        createdBy: "John Doe",
        type: "Type 1",
        subType: "Subtype 1"
    };

    const popupData2 = {
        project: "Project 2",
        description: "Description for Project 2",
        created: "April 14, 2024",
        createdBy: "Jane Smith",
        type: "Type 2",
        subType: "Subtype 2"
    };

    return (
        <div className='dashboard-body'>
          <Header title={"Dashboard"} />

            {/* Common Heading */}
            <div className="common-heading">
                <h2>PROJECTS</h2>
            </div>

            {/* New Section */}
            <div className="section-container">
                <div className="section-box">
                    <div className="box-content">
                        <p className="box-number">10</p>
                        <p>TOTAL PROJECTS ASSIGNED</p>
                    </div>
                </div>
                <div className="section-box">
                    <div className="box-content">
                        <p className="box-number">2</p>
                        <p>OVERDUE PROJECTS COUNT</p>
                    </div>
                </div>
                <div className="section-box">
                    <div className="box-content">
                        <p className="box-number">150</p>
                        <p>TOTAL BILLABLE HOURS</p>
                    </div>
                </div>
                <div className="section-box">
                    <div className="box-content">
                        <p className="box-number">25</p>
                        <p>TOTAL BENCH HOURS</p>
                    </div>
                </div>
            </div>

            {/* Common Heading */}
            <div className="new-common-heading">
                <h2>Leaves(Yearly)</h2>
            </div>

            {/* New Section-2*/}
            <div className="new-section-container">
                <div className="new-section-box">
                    <div className="new-box-content">
                        <p className="new-box-number">3/13</p>
                        <p>PERSONAL LEAVES</p>
                    </div>
                </div>
                <div className="new-section-box">
                    <div className="new-box-content">
                        <p className="new-box-number">0/48</p>
                        <p>UNPAID LEAVES</p>
                    </div>
                </div>
                <div className="new-section-box">
                    <div className="new-box-content">
                        <p className="new-box-number">3</p>
                        <p>TAKEN LEAVES</p>
                    </div>
                </div>
                <div className="new-section-box">
                    <div className="new-box-content">
                        <p className="new-box-number">1</p>
                        <p>AD-HOC TAKEN LEAVES</p>
                    </div>
                </div>
                <div className="new-section-box">
                    <div className="new-box-content">
                        <p className="new-box-number">1</p>
                        <p>TOTAL NOTICES</p>
                    </div>
                </div>
                <div className="new-section-box">
                    <div className="new-box-content">
                        <p className="new-box-number">0</p>
                        <p>TOTAL APPRECIATIONS</p>
                    </div>
                </div>
            </div>

            {/* Common Heading */}
            <div className="Generic-common-heading">
                <h2>Generic</h2>
            </div>

            {/* New Section-3*/}
            <div className="Generic-section-container">
                <div className="Generic-section-box">
                    <div className="Generic-box-content">
                        <p className="Generic-box-number">2</p>
                        <p>EARLY DAY (YEARLY)</p>
                    </div>
                </div>
                <div className="Generic-section-box">
                    <div className="Generic-box-content">
                        <p className="Generic-box-number">1</p>
                        <p>TIMESHEET NOT FILLED (YEARLY)</p>
                    </div>
                </div>
                <div className="Generic-section-box">
                    <div className="Generic-box-content">
                        <p className='extra'>Dhwani Rao Dharmi Tarapara Ansu Dangi</p>
                        <p className="Generic-box-number">
                            <a href="#popup" onClick={() => handlePopupOpen(popupData1)}>+2 More</a>
                        </p>
                        <p>TEAM MEMBERS ON LEAVE TODAY</p>
                    </div>
                </div>
                <div className="Generic-section-box">
                    <div className="Generic-box-content">
                        <p className='extra'>Dhwani Rao Dharmi Tarapara Ansu Dangi</p>
                        <p className="Generic-box-number">
                            <a href="#popup" onClick={() => handlePopupOpen(popupData2)}>+14 More</a>
                        </p>
                        <p>TEAM MEMBERS ON LEAVE IN NEXT 5 WORKING DAYS</p>
                    </div>
                </div>
            </div>

            {/* final Section */}
            <div className="section-container-three">
                <div className="header-box-container">
                    <h2>Overdue Project List</h2>
                    <div className="section-box-three">
                        <div className="box-content-three">
                            <p>No data found</p>
                        </div>
                    </div>
                </div>
                <div className="header-box-container">
                    <h2>Today's Reminder</h2>
                    <div className="section-box-three">
                        <div className="box-content-three">
                            <p>No data found</p>
                        </div>
                    </div>
                </div>
                <div className="header-box-container">
                    <h2>Latest Announcements</h2>
                    <div className="section-box-three">
                        <div className="box-content-three">
                            <p>No data found</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* final Section */}
            <div className="section-container-three">
                <div className="header-box-container">
                    <h2>Latest Blog Posts</h2>
                    <div className="section-box-three">
                        <div className="box-content-three">
                            <p>No data found</p>
                        </div>
                    </div>
                </div>
                <div className="header-box-container">
                    <h2>Latest Rewards</h2>
                    <div className="section-box-three">
                        <div className="box-content-three">
                            <p>No data found</p>
                        </div>
                    </div>
                </div>
                <div className="header-box-container">
                    <h2>Latest Certification</h2>
                    <div className="section-box-three">
                        <div className="box-content-three">
                            <p>No data found</p>
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && <PopupSlider onClose={handlePopupClose} row={popupData} />}
        </div>
    );
};

export default Dashboard;
