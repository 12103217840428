// OpenPage.jsx
import React from "react";
import "./Openpage.css";
import { IoPersonSharp } from "react-icons/io5";
import { SESSION_KEY, setInAsyncStorage } from "../utils/AppConstant";
import usePersistState from "../hook/usePersistState";
import LoginForm2 from "./LoginForm2";

const OpenPage = () => {
  const [userType, setUserType] = usePersistState(
    '',
    SESSION_KEY.USER_TYPE
  );
  const handleSelectUserType = (role) => {
    setUserType(role);
  };

  return userType === '' ? (
    <div className="Forgrt-Password">
      <div>
        <img
          src="\image\Profile-Picture-removebg-preview.png"
          alt="Logo"
          className="Password-logo"
        />
      </div>
      <div className="page-box">
        <div className="profile-box">
          <div
            className="profile-1"
            // onClick={
            //   () =>
            //   onIndividualClick("employee")
            //   }
          >
            <IoPersonSharp className="page-icon" />
            <p>Employee</p>
          </div>
          <div
            className="profile-2"
            onClick={() => handleSelectUserType("customer")}
          >
            <IoPersonSharp className="page-icon" />
            <p>Customer</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoginForm2 />
  );
};

export default OpenPage;
