const setInAsyncStorage = async (key, value) => {
  await localStorage.setItem(key, JSON.stringify(value));
};

const getFromAsync = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const removeFromAsync = (key) => {
  return localStorage.removeItem(key);
};

const SESSION_KEY = {
  USER_DATA: "userData",
  USER_TYPE: "user_type",
};

const ROUTE = {
  DASHBOARD: "/Dashboard2",
  LOGIN: "/login",
  REGISTER: "/register",
  SERVICE: "/service",
};

export { setInAsyncStorage, getFromAsync, removeFromAsync, SESSION_KEY, ROUTE };
