
import React, { useState } from 'react';
import './Calendar.css'; // Import the CSS file
import { IoShareOutline } from "react-icons/io5";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';

const MyCalendar = ({ onLogout }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        // Call the logout function passed as a prop
        onLogout();
    };

    // Dummy data for events, you can replace this with your actual events data
    const events = [
        {
            title: 'Meeting',
            start: new Date(2024, 3, 5, 10, 0), // Year, Month (0-indexed), Day, Hour, Minute
            end: new Date(2024, 3, 5, 12, 0),
        },
        {
            title: 'Lunch',
            start: new Date(2024, 3, 6, 12, 0),
            end: new Date(2024, 3, 6, 13, 0),
        },
    ];

    const localizer = momentLocalizer(moment);

    return (
        <div className='calendar-body'>
          <Header title={"Calender"} />

            {/* React Big Calendar */}
            <div className="calendar-container">
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                />
            </div>
        </div>
    );
};

export default MyCalendar;
