import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiCodeBlock } from "react-icons/bi";
import { BsStar } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {
  getFromAsync,
  removeFromAsync,
  ROUTE,
  SESSION_KEY,
} from "../../utils/AppConstant";
import usePersistState from "../../hook/usePersistState";
const Header = ({ title,cartList }) => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [user, setUser] = useState({});
  const [cart] = usePersistState([], "cart");
  const navigate = useNavigate();
  const handleLogout = () => {
    removeFromAsync(SESSION_KEY?.USER_DATA);
    removeFromAsync("web:" + SESSION_KEY?.USER_TYPE);
    navigate(ROUTE?.LOGIN);
  };
  const handleProfileClick = () => {
    setShowUserInfo(!showUserInfo);
  };
  useEffect(() => {
    getUserData();
  }, [cartList]);

  const getUserData = async () => {
    const user = await getFromAsync(SESSION_KEY.USER_DATA);
    let arr= cartList!==undefined ? cartList : cart
    const filterCart = arr?.map((list) => {
      return list?.user_id === user?.id;
    });
    setUser({ user, cart: filterCart });
  };
  return (
    <div className="dashboard2-heading">
      <div className="dashboard-icon-navbar">
        <BiCodeBlock className="BiCodeBlock" />
        <BsStar className="BsStar" />
        <h1>{title}</h1>
      </div>

      <div className="Page1-Right-heading">
        <div className="cart-icon-container" onClick={() => navigate("/cart")}>
          <AiOutlineShoppingCart className="AiOutlineShoppingCart" />
          {user?.cart?.length > 0 && (
            <span className="cart-count">{user?.cart?.length}</span>
          )}
        </div>
        <div className="input-navbar-heading">
          <BiSearch className="BiSearch pl-2" />
          <input type="search" placeholder="Search" />
        </div>
        <div className="IoNotificationsOutline">
          <IoNotificationsOutline />
        </div>
        <div className="profile-container" onClick={handleProfileClick}>
          <img
            src="\image\Group 15.png"
            alt="Profile"
            className="profile-image"
          />
          {showUserInfo && (
            <div className="dropdown-menu">
              <div className="user-info">
                <Link to="/ProfilePage">
                  <div className="user-info-content">
                    <img
                      src="\image\Group 15.png"
                      alt="Profile"
                      className="profile-image-small"
                    />
                    <div>
                      <p className="user-name">{user?.user?.name}</p>
                      <p className="user-subtitle">{user?.user?.company}</p>
                    </div>
                  </div>
                </Link>
              </div>
              <hr className="separator" />
              <div className="logout" onClick={handleLogout}>
                <p>Log Out</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
