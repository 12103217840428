import React, { useState } from 'react';
import './Attendance.css'; // Import the CSS file
import { IoShareOutline } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Attendance = ({ onLogout }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        // Call the logout function passed as a prop
        onLogout();
    };

    return (
        <div className='attendance-body'>
            <div className="attendance-heading">
                <h1>Attendance</h1>
                <div className="profile-container" onClick={handleProfileClick}>
                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image" />
                    {showUserInfo && (
                        <div className="dropdown-menu">
                            <div className="user-info">
                                <Link to="/ProfilePage"> {/* Link to ProfilePage */}
                                    <div className="user-info-content">
                                        <img src=".\image\Group 15.png" alt="Profile" className="profile-image-small" />
                                        <div>
                                            <p className="user-name">Samraddhi Gupta</p>
                                            <p className="user-subtitle">Software Engineer</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <hr className="separator" />
                            <div className="logout" onClick={handleLogout}>
                                <IoShareOutline size={15} style={{ marginRight: '5px' }} />
                                <p>Log Out</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="attendance-content">
                <div className="attendance-details">
                    <div className="attendance-info">
                        <input type="date" id="date" name="date" />
                    </div>
                    <div className="attendance-timings">
                        <p>Working Hour: 00:00&nbsp;&nbsp;&nbsp; |</p>
                        <p>Total Hour: 00:00</p>
                    </div>

                </div>
                  {/* New Section - Table with 6 headings in a box */}
            <div className="table-box-two">
                <table className="attendance-table">
                    <thead>
                        <tr>
                            <th>ANNOUNCED BY</th>
                            <th>TITLE</th>
                            <th>TYPE</th>
                            <th>ATTACHMENT</th>
                            <th>DESCRIPTION</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Samraddhi Gupta</td>
                            <td>Employee Policies</td>
                            <td>News</td>
                            <td>Employee-Plicies-2024.pdf</td>
                            <td>Updated Employee Policy document</td>
                            <td>
                            <a href="#" onClick={() => console.log("Icon Clicked!")}>
                                    <FaRegEye />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Data 7</td>
                            <td>Data 8</td>
                            <td>Data 9</td>
                            <td>Data 10</td>
                            <td>Data 11</td>
                            <td>
                            <a href="#" onClick={() => console.log("Icon Clicked!")}>
                                    <FaRegEye />
                                </a>
                            </td>
                        </tr>
                        {/* Add more rows as needed */}
                    </tbody>
                </table>
            </div>

            </div>


        </div>
    );
};

export default Attendance;
