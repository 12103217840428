import React from 'react';
import './LoginForm.css';
import { HiOutlineMail } from 'react-icons/hi'; 

const ForgotPasswordPage = ({ onSendResetLink }) => {
  return (
    <div className='Forgrt-Password'>
      <div>
        <img src="\image\Profile-Picture-removebg-preview.png" alt="Logo" className="Password-logo" />
      </div>
      <div className='forget-box'>
        <h2>Forgot password</h2>
        <p>Please select the option to receive a <br/>password reset link.</p>
        <div className="reset-option">
          <div className="email-icon">
            <HiOutlineMail />
          </div>
          <div className="reset-description">
            <span>Reset via Email</span>
            <p>Get a unique password reset link sent to your registered email address.</p> 
          </div>
        </div>
        <button className="send-button" onClick={onSendResetLink}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
