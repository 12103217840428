import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiCodeBlock } from "react-icons/bi";
import { BsStar } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import './Request.css'
import { FaBusinessTime } from "react-icons/fa6";
import Header from '../components/Header/Header';

const Request = ({ onLogout }) => {
    const [selectedMonth, setSelectedMonth] = useState('October');
    const navigate = useNavigate();
    const [showUserInfo, setShowUserInfo] = useState(false);
    
    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
      };

      const postApoinment=()=>{
        window.open("https://calendly.com/webidesigners01")
      }



    return (
        <div className='dashboard2-body'>
            <div className='Dashboard-container'>
            <Header title={"Request"} />
          
                
           <div className='Request-container'>
                <div className='Request-main'>
                        <div className='Reuest-box'>
                                    <FaBusinessTime className='FaBusinessTime'/>
                                    <h1>Book an Appointment</h1>
                        </div>
                    <button onClick={()=>postApoinment()}>Book an Appointment</button>
                </div>
           </div>
           
     </div>
    </div>
            
    );
};

export default Request;
