import React, { useState, useEffect } from 'react';
import './Applyleave.css'; 
import { IoShareOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Leave = ({ onLogout }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        option1: false,
        option2: false,
        option3: false,
    });
    const [showStartDate, setShowStartDate] = useState(false);
    const [showEndDate, setShowEndDate] = useState(false);
    const [showPartOfDay, setShowPartOfDay] = useState(false);

    useEffect(() => {
        const savedCheckboxes = JSON.parse(localStorage.getItem('checkboxes'));
        if (savedCheckboxes) {
            setCheckboxes(savedCheckboxes);
            setShowStartDate(savedCheckboxes.option1);
            setShowEndDate(savedCheckboxes.option2);
            setShowPartOfDay(savedCheckboxes.option3);
        }
    }, []);

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        onLogout();
    };

    const handleCheckboxChange = (checkboxName) => {
        const updatedCheckboxes = { ...checkboxes };
        Object.keys(updatedCheckboxes).forEach(key => {
            if (key !== checkboxName) {
                updatedCheckboxes[key] = false;
            }
        });
        updatedCheckboxes[checkboxName] = !updatedCheckboxes[checkboxName];
        setCheckboxes(updatedCheckboxes);
        localStorage.setItem('checkboxes', JSON.stringify(updatedCheckboxes));

        // Set visibility for start date, end date, and part of day inputs
        if (checkboxName === 'option1') {
            setShowStartDate(true);
            setShowEndDate(false);
            setShowPartOfDay(false);
        } else if (checkboxName === 'option2') {
            setShowStartDate(true);
            setShowEndDate(true);
            setShowPartOfDay(false);
        } else if (checkboxName === 'option3') {
            setShowStartDate(true);
            setShowEndDate(false);
            setShowPartOfDay(true);
        }
    };

    const handleSave = () => {
        // Add your save functionality here
        console.log("Save button clicked");
    };

    return (
        <div className='leave-body'>
            <div className="leave-heading">
                <h1>Apply Leave</h1>
                <div className="profile-container" onClick={handleProfileClick}>
                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image" />
                    {showUserInfo && (
                        <div className="dropdown-menu">
                            <div className="user-info">
                                <Link to="/ProfilePage"> {/* Link to ProfilePage */}
                                    <div className="user-info-content">
                                        <img src=".\image\Group 15.png" alt="Profile" className="profile-image-small" />
                                        <div>
                                            <p className="user-name">Samraddhi Gupta</p>
                                            <p className="user-subtitle">Software Engineer</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <hr className="separator" />
                            <div className="logout" onClick={handleLogout}>
                                <IoShareOutline size={15} style={{ marginRight: '5px' }} />
                                <p>Log Out</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            
            {/* Main Section with Form and Table */}
            <div className="main-section">
                <div className="leave-section">
                <div className="box">
                            <p className="left-paragraph">Request leave for all days at once. Saturdays, Sundays, or holidays will not be included in the calculation.</p>
                        </div>
                    {/* Form Section */}
                    <div className="form-box">
                       
                        <form className="leave-form">
                            <div className="form-group">
                                <label htmlFor="category" className="form-label">SELECT CATEGORY <span>*</span></label>
                                <select id="category" name="category" className="form-input">
                                    <option value="" disabled selected>Select Category</option>
                                    <option value="sick">Sick Leave</option>
                                    <option value="vacation">Vacation</option>
                                    <option value="personal">Personal Leave</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="option1" className="form-label">DURATION <span>*</span></label>
                                <div className="checkboxes">
                                    <div className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id="option1"
                                            name="option1"
                                            checked={checkboxes.option1}
                                            onChange={() => handleCheckboxChange('option1')}
                                        />
                                        <label className="option" htmlFor="option1">Single Day</label>
                                    </div>
                                    <div className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id="option2"
                                            name="option2"
                                            checked={checkboxes.option2}
                                            onChange={() => handleCheckboxChange('option2')}
                                        />
                                        <label className="option" htmlFor="option2">Multiple Days</label>
                                    </div>
                                    <div className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id="option3"
                                            name="option3"
                                            checked={checkboxes.option3}
                                            onChange={() => handleCheckboxChange('option3')}
                                        />
                                        <label className="option" htmlFor="option3">Hours</label>
                                    </div>
                                </div>
                            </div>
                            {showStartDate && (
                                <div className="form-group">
                                    <label htmlFor="start-date" className="form-label">START DATE <span>*</span></label>
                                    <input type="date" id="start-date" name="start-date" className="form-input" />
                                </div>
                            )}
                            {showEndDate && (
                                <div className="form-group">
                                    <label htmlFor="end-date" className="form-label">END DATE <span>*</span></label>
                                    <input type="date" id="end-date" name="end-date" className="form-input" />
                                </div>
                            )}
                            {showPartOfDay && (
                                <div className="form-group">
                                    <label htmlFor="part-of-day" className="form-label">PART OF DAY <span>*</span></label>
                                    <select id="part-of-day" name="part-of-day" className="form-input">
                                        <option value="" disabled selected>Select Part of Day</option>
                                        <option value="morning">Morning</option>
                                        <option value="afternoon">Afternoon</option>
                                        <option value="evening">Evening</option>
                                    </select>
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="reason" className="form-label">REASON <span>*</span></label>
                                <textarea id="reason" name="reason" rows="4" className="form-input" />
                            </div>
                            <div className="form-group">
                                <button className='Save-btn' type="button" onClick={handleSave}>Save</button>
                            </div>
                            
                        </form>
                        
                    </div>
                </div>
                {/* Table Section */}
                <div className="table-section">
                    <table className="leave-table">
                        <thead>
                            <tr>
                                <th colSpan="2">My Leave Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Personal</td>
                                <td>0/0</td>
                            </tr>
                            <tr>
                                <td>Unpaid</td>
                                <td>0/0</td>
                            </tr>
                            <tr>
                                <td>Appreciation Leave(C-Off)</td>
                                <td>0/0</td>
                            </tr>
                        
                        </tbody>
                        <tbody className='extra-row'>
                        <tr>
                                <td>Total:</td>
                                <td>0</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    )
    
};

export default Leave;
