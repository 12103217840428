import React, { useEffect, useState } from "react";
import { IoShareOutline } from "react-icons/io5";
import "./ProfilePage.css";
import Select from "react-select";
import { SESSION_KEY, getFromAsync } from "../utils/AppConstant";

const ProfilePage = ({ onLogout }) => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(getFromAsync(SESSION_KEY.USER_DATA));
  }, []);

  const handleProfileClick = () => {
    setShowUserInfo(!showUserInfo);
  };

  const handleLogout = () => {
    onLogout();
  };

  // Options for the dropdowns
  const primaryTechnologyOptions = [
    { value: "javascript", label: "JavaScript" },
    { value: "python", label: "Python" },
    { value: "java", label: "Java" },
    { value: "react", label: "React" },
    // Add more options as needed
  ];

  return (
    <div className="profile_pages">
      <div className="profile-headings">
        <h1>Profile</h1>
        <div className="profile-container" onClick={handleProfileClick}>
          <img
            src="./image/Profile-Picture.jpg"
            alt="Profile"
            className="profile-image"
          />
          {showUserInfo && (
            <div className="dropdown-menu">
              <div className="user-info">
                <div className="user-info-content">
                  <img
                    src="./image/Profile-Picture.jpg"
                    alt="Profile"
                    className="profile-image-small"
                  />
                  <div>
                    <p className="user-name">Samraddhi Gupta</p>
                    <p className="user-subtitle">Software Engineer</p>
                  </div>
                </div>
              </div>
              <hr className="separator" />
              <div className="logout" onClick={handleLogout}>
                <IoShareOutline size={15} style={{ marginRight: "5px" }} />
                <p>Log Out</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Second ProfilePage Component */}
      <div className="profile-page-box">
        <div className="profile-page">
          <div className="profile-header">
            <img
              className="profile-image-large"
              src="./image/Profile-Picture.jpg"
              alt="User Profile"
            />
          </div>

          <div className="profile-info">
            <div className="input-row">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={data?.name}
              />
            </div>
            <div className="input-row">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={data?.email}
              />
            </div>
            <div className="input-row">
              <label htmlFor="email">Company:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={data?.company}
              />
            </div>
            <div className="input-row">
              <label htmlFor="phone">Phone Number:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={data?.mobile_number}
                placeholder="Enter your phone number"
              />
            </div>
          </div>

          <div className="Input-group">
            <button className="Upload-btn" type="button">
              Update profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
