import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import SideBar from "./components/Sidebar/SideBar";
import Dashboard from "./pages/Dashborad/Dashboard.jsx";
import Project from "./pages/Project/Project.jsx";
import Calendar from "./pages/Calendar/Calendar.jsx";
import Timesheet from "./pages/Timesheet/Timesheet.jsx";
import AddTimesheet from "./pages/Timesheet/Addtimesheet.jsx";
import Attendance from "./pages/Attendance/Attendance.jsx";
import Applyleave from "./pages/Leave/Applyleave.jsx";
import Leave from "./pages/Leave/Leave.jsx";
import Announcements from "./pages/Announcements/Announcements.jsx";
import Notice from "./pages/Notice and Appreciation/Notice.jsx";
import ProfilePage from "./pages/ProfilePage.jsx";
import Dashboard2 from "./pages/dashbord2/dashbord2.jsx";
import Request from "./Request/Request.jsx";
import ForgotPasswordPage from "./LoginForm/ForgotPasswordPage.js";
import NewPassword from "./LoginForm/NewPassword.js";
import CartPage from "./pages/services/page1/CartPage.js"; // Import CartPage
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { getFromAsync, ROUTE, SESSION_KEY } from "./utils/AppConstant.js";
import Login from "./pages/login/index.js";
import SignUp from "./pages/register/index.js";
import Services from "./pages/services/index.jsx";
import Service from "./pages/services/page1/service.jsx";

function App() {
  let location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(null);

  // console.log("pathname", location);
  const handleLogout = () => {
    // removeFromAsync(SESSION_KEY.USER_DATA);
    // removeFromAsync(SESSION_KEY.USER_TYPE);
    navigate(ROUTE?.LOGIN);
  };
  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async () => {
    const user = await getFromAsync(SESSION_KEY.USER_DATA);
    if (user?.token !== undefined || user!==null ) {
      if (location.pathname === ROUTE?.LOGIN) {
        navigate(ROUTE?.DASHBOARD);
      }
    } else {
      if (location.pathname === ROUTE?.REGISTER) {
        navigate(ROUTE?.REGISTER);
      } else {
        navigate(ROUTE?.LOGIN);
      }
    }
  };
  return (
    <div className="App">
      <div
        className={`${
          location?.pathname !== ROUTE?.LOGIN &&
          location?.pathname !== ROUTE?.REGISTER &&
          "flex"
        }`}
      >
        {location?.pathname !== ROUTE?.LOGIN &&
          location.pathname !== ROUTE?.REGISTER && (
            <SideBar
              isLoggedIn={isLoggedIn}
              userType={selectedUserType}
            />
          )}

        <div className="w-full mr-4">
          <Routes>
            <Route path="/" element={<Dashboard  />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route
              path="/Project"
              element={<Project  />}
            />
            <Route
              path="/Calendar"
              element={<Calendar  />}
            />
            <Route
              path="/Attendance"
              element={<Attendance />}
            />
            <Route
              path="/Timesheet"
              element={<Timesheet />}
            />
            <Route
              path="/AddTimesheet"
              element={<AddTimesheet  />}
            />
            <Route
              path="/Applyleave"
              element={<Applyleave />}
            />
            <Route path="/Leave" element={<Leave />} />
            <Route
              path="/Announcements"
              element={<Announcements  />}
            />
            <Route
              path="/Notice"
              element={<Notice  />}
            />
            <Route
              path="/ProfilePage"
              element={<ProfilePage  />}
            />
            <Route
              path="/Dashboard2"
              element={<Dashboard2  />}
            />
            <Route
              path="/Services"
              element={<Services  />}
            />
            <Route
              path="/Request"
              element={<Request  />}
            />
            <Route
              path="/ForgotPasswordPage"
              element={<ForgotPasswordPage  />}
            />

            <Route
              path="/NewPassword"
              element={<NewPassword />}
            />
            <Route
              path={ROUTE?.SERVICE + "/:data"}
              element={<Service  />}
            />
            <Route path="/cart" element={<CartPage />} />
            <Route path="*" element={<>Not found</>} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
