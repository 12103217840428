import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiCodeBlock } from "react-icons/bi";
import { BsStar } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "./CartPage.css";
import { get, post } from "../../../services/ApiService";
import { API_CONSTANT } from "../../../services/ApiConstant";
import { toast } from "react-toastify";
import usePersistState from "../../../hook/usePersistState";
import { v4 as uuidv4 } from "uuid";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import { getFromAsync, SESSION_KEY, setInAsyncStorage } from "../../../utils/AppConstant";
import Header from "../../../components/Header/Header";
const CartPage = ({ onLogout }) => {
  const [selectedMonth, setSelectedMonth] = useState("October");
  const navigate = useNavigate();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [cart, setCart] = usePersistState([], "cart");
  const [filterCart,setFilterCart]=useState([])
  const [openTermAndC, setOpenTermAndC] = useState(false);
  const [agreeTerms,setAgreeTerms]=useState(false)
  
  const handleProfileClick = () => {
    setShowUserInfo(!showUserInfo);
  };


  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  useEffect(() => {
    // getCartItems();
    getTermsAndCondition();
  }, []);

  const getCart=async()=>{
    const user=await getFromAsync(SESSION_KEY?.USER_DATA)
      const arr = cart?.filter((list)=>{
        console.log("list",list?.user_id===user?.id);
      return list?.user_id===user?.id
          
     })
    setFilterCart(arr)
  }
useEffect(()=>{
  getCart()
},[cart])

const handleRemoveCart=(list)=>{
  const updatedCartItems = cart.filter(item => item.id !== list?.id);
  toast.success("Item removed to cart");
    // Update state    toast.success("Item deleted to cart");
    setCart(updatedCartItems);
}

  const getTermsAndCondition = () => {
    get(API_CONSTANT?.termsList)
      .then((res) => {
        setTermsAndConditions(res?.data?.data);
      })
      .catch((e) => {
        toast.error(e?.message || e?.data?.ErrorMessage || String(e));
      });
  };

  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId
          ? { ...item, quantity: item.quantity > 1 ? item.quantity - 1 : 1 }
          : item
      )
    );
  };
  const calculateTotalPrice = () => {
    return filterCart.reduce((total, item) => total + item.quantity * item.price, 0);
  };

  const makePayment = async (e) => {
    if(agreeTerms===false){
      toast.error("Please accept Terms & conditions")
    }
    else{
      const data = new FormData();
      data.append("service_list", filterCart);

      post(`${API_CONSTANT?.payment}`, data, false)
        .then((res) => {
          toast.success(res?.data?.message);
          setCart([])
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
    // e.preventDefault();
    // const transactionid = "Tr-" + uuidv4().toString().slice(-6);
    // const payload = {
    //   merchantId: process.env.REACT_APP_MERCHANT_ID,
    //   merchantTransactionId: transactionid,
    //   merchantUserId: "MUID-" + uuidv4().toString().slice(-6),
    //   amount: 10000,
    //   redirectUrl: `http://localhost:3000/api/status/${transactionid}`,
    //   redirectMode: "POST",
    //   callbackUrl: `http://localhost:3000/api/status/${transactionid}`,
    //   mobileNumber: "9999999999",
    //   paymentInstrument: {
    //     type: "PAY_PAGE",
    //   },
    // };
    // const dataPayload = JSON.stringify(payload);
    // const dataBase64 = btoa(dataPayload);
    // const fullURL = dataBase64 + "/pg/v1/pay" + process.env.REACT_APP_SALT_KEY;
    // const dataSha256 = sha256(fullURL);
    // const checksum = dataSha256 + "###" + process.env.REACT_APP_SALT_INDEX;
    // const UAT_PAY_API_URL =
    //   "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
    // const response = await axios.post(
    //   UAT_PAY_API_URL,
    //   {
    //     request: dataBase64,
    //   },
    //   {
    //     headers: {
    //       accept: "application/json",
    //       "Content-Type": "application/json",
    //       "X-VERIFY": checksum,
    //     },
    //   }
    // );
    // const redirect = response.data.data.instrumentResponse.redirectInfo.url;
    // navigate(redirect);
  };

  function createMarkup(description) {
    return {
      __html: description,
    };
  }

  return (
    <div className="dashboard2-body">
      <div className="Dashboard-container">
     <Header title={"Cart"}  cartList={cart} />
        <div>
          <div className="cart-page">
            <div className="Buttom-line-cart">
              <div className="cart-headings">
                <AiOutlineShoppingCart className="cart-icon-payment" />
                <h1>Your Cart</h1>
              </div>
              {filterCart?.length === 0 ? (
                <p>Your cart is empty</p>
              ) : (
                <ul>
                  {filterCart?.map((item, index) => {
                    
                    return(      <li key={index}>
                      <div className="cart-item">
                        <div className="Cart-item-heading">
                          <h2>{item.name}</h2>
                          <p>Price: {item.price * item?.quantity} INR</p>
                        </div>
                      <div className="">   <div className="flex items-center justify-end mb-4 cursor-pointer" onClick={()=>handleRemoveCart(item)}><img
                    src="./image/delete.png"
                    alt="Profile"
                  
                    className="h-[24px] w-[24px]"
                  /></div>
                        <div className="Cart-btn">
                     
                          <button
                            className="Cart-button"
                            onClick={() => decreaseQuantity(item?.id)}
                          >
                            -
                          </button>
                          <span className="Cart-number">{item?.quantity}</span>
                          <button
                            className="Cart-button"
                            onClick={() => increaseQuantity(item?.id)}
                          >
                            +
                          </button>
                        </div>
                        </div>
                      </div>
                    </li>)
              
})}
                </ul>
              )}
            </div>

            {/* Button-pay-btn */}
            <div className="Payment-container">
              <div className="Heading-pay">
                <input
                  type="checkbox"
                  checked={openTermAndC === true}
                  value={openTermAndC}
                  onChange={(e) => setOpenTermAndC(e?.target?.checked)}
                />
                <p>T&C</p>
              </div>

              <div className="Total-main">
                <div className="Total-heading">
                  <p>Total price</p>
                  <p>{calculateTotalPrice()}</p>
                </div>
                <div className="pay-btn" onClick={(e) => makePayment(e)}>
                  <p>Pay</p>
                </div>
              </div>
            </div>
            {/* Button-pay-btn */}
          </div>
        </div>
      </div>
      <Transition appear show={openTermAndC}>
        <Dialog
          as="div"
          className="relative z-10 focus:outline-none"
          onClose={() => setOpenTermAndC(false)}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-10 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-md p-6 bg-white rounded-xl backdrop-blur-4xl">
                  <DialogTitle
                    as="h3"
                    className="font-bold text-center text-black text-base/7"
                  >
                    Terms & Conditions
                  </DialogTitle>
                  <p className="mt-2 text-sm/6 text-black/50">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        termsAndConditions?.description
                      )}
                    />
                  </p>
                  <div className="flex items-center justify-end mt-4">
                    <button className="text-white bg-[#00ac24]  text-center pt-2 pb-3 px-4 text-base rounded-lg" onClick={()=>{
                      setAgreeTerms(true)
                      setOpenTermAndC(false)}}>Agree</button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default CartPage;
