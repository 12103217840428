import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginForm.css";
import { API_CONSTANT } from "../services/ApiConstant";
import { toast } from "react-toastify";
import { ROUTE } from "../utils/AppConstant";
import { post } from "../services/ApiService";

const SignupForm = () => {
  const navigate = useNavigate();
  let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  const [values, setValues] = useState({
    email: "",
    password: "",
    name: "",
    mobile_number: "",
    company: "",
  });
  const [isSubmitting,setIsSubmitting]=useState(false)
  const handleSubmit = (event) => {
    event?.preventDefault()
    setIsSubmitting(true)
    event.preventDefault();
    if (
      values?.email === "" ||
      values?.password === "" ||
      values?.company === "" ||
      values?.mobile_number === "" ||
      values?.name === ""
    ) {
      
    } else if (!emailRegex.test(values?.email)) {
      toast.error("Please insert a valid email");
    } else {
      const data = new FormData();
      data.append("email", values?.email);
      data.append("password", values?.password);
      data.append("name", values?.name);
      data.append("mobile_number", values?.mobile_number);
      data.append("company", values?.company);
      post(`${API_CONSTANT?.register}`, data, false)
        .then((res) => {
          setIsSubmitting(false)
          navigate(ROUTE?.LOGIN);
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          console.log("err",err);
          toast.error(err?.response?.data?.error);

        });
    }
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div className="form-container">
      <img
        src="\image\Profile-Picture-removebg-preview.png"
        alt="Logo"
        className="logo"
      />
      <form className="signupform">
        <h2> Get Started!</h2>
        <p>Your New Journey Begins Now. </p>
        {/* <div className="google-login">
          <button className="google-login-btn" type="button">
            <img
              src=".\image\google-color-icon 1.png"
              alt="Google Icon"
              className="google-icon"
            />
            Sign up with Google
          </button>
          <p className="middle">or</p>
        </div> */}
        <div className="form-group">
          <input
            type="text"
            name="email"
            id="email"
              className="!mb-1"
            placeholder="Email id"
            value={values?.email}
            onChange={(e) => handleChangeInput(e)}
          />
          {isSubmitting && values?.email==="" && <div className="text-sm text-red-500">Email is required</div>}
        </div>
        <div className="form-group">
          <input
            type="password"
            id="password"
            name="password"
              className="!mb-1"
            placeholder="Password"
            value={values?.password}
            onChange={(e) => handleChangeInput(e)}
          />
              {isSubmitting && values?.password==="" && <div className="text-sm text-red-500">Password is required</div>}
        </div>
        <div className="form-group">
          <input
            type="text"
            id="userName"
              className="!mb-1"
            name="name"
            placeholder="UserName"
            value={values?.name}
            onChange={(e) => handleChangeInput(e)}
          />
            {isSubmitting && values?.name==="" && <div className="text-sm text-red-500">Name is required</div>}
        </div>
        <div className="form-group">
          <input
            type="number"
            id="mobile_number"
            name="mobile_number"
              className="!mb-1"
            placeholder="mobile_number"
            value={values?.mobile_number}
            onChange={(e) => handleChangeInput(e)}
          />
           {isSubmitting && values?.mobile_number==="" && <div className="text-sm text-red-500">Mobile number is required</div>}
        </div>
        <div className="form-group">
          <input
            type="text"
            id="company"
            name="company"
              className="!mb-1"
            placeholder="Company name"
            value={values?.company}
            onChange={(e) => handleChangeInput(e)}
          />
           {isSubmitting && values?.company==="" && <div className="text-sm text-red-500">Company is required</div>}
        </div>
        <button onClick={handleSubmit} className="Login-button">
          Sign up
        </button>
        <p>
          Already have an account?{" "}
          <a onClick={() => navigate(ROUTE?.LOGIN)}>Log in</a>
        </p>
      </form>
    </div>
  );
};

export default SignupForm;
