const API_CONSTANT = {
  login: "/login",
  register: "/register",
  category: "/category-list",
  service: "/service-list",
  cartList: "/cart-list",
  termsList: "/terms-list",
  paymentList:"/payment-list",
  payment:"/payment"
};
export { API_CONSTANT };
