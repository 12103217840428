import React, { useState } from 'react';
import './LeaveDetails.css'; // Import the CSS file
import { IoShareOutline } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Link } from 'react-router-dom';

const LeaveDetails = ({ onLogout }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        // Call the onLogout function passed as a prop
        onLogout();
    };

    const handleApplyLeave = () => {
        navigate('/apply-leave'); // Navigate to the "Leave" page
    };

    // Sample data for the table
    const tableData = [
        {
            id: 1,
            heading1: 'Data 1',
            heading2: 'Data 2',
            heading3: 'Data 3',
            heading4: 'Data 4',
            heading5: 'Data 5',
            heading6: 'Data 6',
            heading7: 'Data 7',
            heading8: <FaEye />,
        },
        {
            id: 2,
            heading1: 'Data 1',
            heading2: 'Data 2',
            heading3: 'Data 3',
            heading4: 'Data 4',
            heading5: 'Data 5',
            heading6: 'Data 6',
            heading7: 'Data 7',
            heading8: <FaEye />,
        },
    ];

    return (
        <div className='leave-details-body'>
            <div className="leave-details-heading">
                <h1>All Leave</h1>
                <div className="profile-container" onClick={handleProfileClick}>
                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image" />
                    {showUserInfo && (
                        <div className="dropdown-menu">
                            <div className="user-info">
                            <Link to="/ProfilePage"> {/* Link to ProfilePage */}
                                <div className="user-info-content">
                                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image-small" />
                                    <div>
                                        <p className="user-name">Samraddhi Gupta</p>
                                        <p className="user-subtitle">Software Engineer</p>
                                    </div>
                                    </div>
                                    </Link>
                            </div>
                            <hr className="separator" />
                            <div className="logout" onClick={handleLogout}>
                                <IoShareOutline size={15} style={{ marginRight: '5px' }} />
                                <p>Log Out</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        
            <div className="enclosed-box">
                <div className="leave-details-section">
                    <div className="leave-box left-box">
                        <p className="leave-box-title">Personal Leave</p>
                        <p className="leave-box-count">0/0</p>
                    </div>
                    <div className="leave-box left-box">
                        <p className="leave-box-title">Onpaid Leave</p>
                        <p className="leave-box-count">0/0</p>
                    </div>
                </div>

                {/* Button on the right side */}
                <div className="button-container">
                    {/* Call handleApplyLeave function onClick */}
                    <button className="right-button" onClick={handleApplyLeave}>+ Apply Leave</button>
                </div>

                {/* Table with 8 headings and sample data */}
                <div className="table-container">
                    <table className="table-detail">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>LEAVE CATEGORY</th>
                                <th>LEAVE DATE</th>
                                <th>DURATION</th>
                                <th>STATUS</th>
                                <th>APPLIED BY</th>
                                <th>APPLIED ON</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((data) => (
                                <tr key={data.id}>
                                    <td>{data.heading1}</td>
                                    <td>{data.heading2}</td>
                                    <td>{data.heading3}</td>
                                    <td>{data.heading4}</td>
                                    <td className="heading5-cell"><span>{data.heading5}</span></td>
                                    <td>{data.heading6}</td>
                                    <td>{data.heading7}</td>
                                    <td className='Heading8-cell'><span>{data.heading8}</span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default LeaveDetails;
