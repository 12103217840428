import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "./page1.css";
import { API_CONSTANT } from "../../../services/ApiConstant";
import { get, post } from "../../../services/ApiService";
import { toast } from "react-toastify";
import usePersistState from "../../../hook/usePersistState";
import { getFromAsync, SESSION_KEY } from "../../../utils/AppConstant";
import Header from "../../../components/Header/Header";

const Service = () => {
  const navigate = useNavigate();
  const { data: id } = useParams();

  const [showUserInfo, setShowUserInfo] = useState(false);
  const [cart, setCart] = usePersistState([], "cart");
  const [serviceList, setServiceList] = useState();
  const handleProfileClick = () => {
    setShowUserInfo(!showUserInfo);
  };


  const handleAddToCart = async (product) => {
    toast.success("Items added to cart");
    const user = await getFromAsync(SESSION_KEY?.USER_DATA);
    setCart((prevCart) => {
      const user_id = prevCart.find((item) => item.user_id === user.id);
      const isProductInCart = prevCart.find((item) => item.id === product.id);
      const serviceInCart = serviceList?.find(
        (item) => item?.id === product.id
      );
      if (isProductInCart && user_id) {
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + serviceInCart.quantity }
            : item
        );
      }
      return [
        ...prevCart,
        { ...product, quantity: serviceInCart.quantity, user_id: user?.id },
      ];
    });
  };

  useEffect(() => {
    getService();
  }, []);

  const getService = () => {
    post(API_CONSTANT.service, { category_id: id })
      .then((res) => {
        const arr = res?.data?.data?.map((list) => {
          return { ...list, quantity: 1 };
        });
        setServiceList(arr);
      })
      .catch((e) => {
        toast.error(e?.message || e?.data?.ErrorMessage || String(e));
      });
  };
  function createMarkup(description) {
    return {
      __html: description,
    };
  }

  const increseQuantity = (productId) => {
    setServiceList((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };
  const decreseQuantity = (productId) => {
    setServiceList((prevCart) =>
      prevCart.map((item) =>
        item.id === productId
          ? { ...item, quantity: item.quantity > 1 ? item.quantity - 1 : 1 }
          : item
      )
    );
  };

  return (
    <div className="dashboard2-body">
      <div className="Dashboard-container">
      <Header title={"Services"} cartList={cart}/>
        <div>
          {/* Section-start */}
          <div className="Page1-container">
            <div className="Page1-Main">
              <h1>Creative Design</h1>
              <hr className="Page1-line"></hr>
              <div className="category-list">
                {serviceList?.map((list) => {
                  return (
                    <div className="Payment-box">
                      <div className="Payment-headings">
                        <h1>{list?.name}</h1>
                        <div
                          className="icon"
                          onClick={() => handleAddToCart(list)}
                        >
                          <AiOutlineShoppingCart className="ShoppingCart-icon" />
                        </div>
                      </div>
                      <p className="Price-para">
                        Price - {list?.price ?? 0} INR
                      </p>
                      <p className="para-main">
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            list?.description
                          )}
                        />
                      </p>
                      <div className="Quantity-box">
                        <div className="Quantity-control">
                          <div className="Quantity-btn">
                            <button
                              className="Quantity-button"
                              onClick={() => decreseQuantity(list?.id)}
                            >
                              -
                            </button>
                            <span className="Quantity-number">
                              {list?.quantity}
                            </span>
                            <button
                              className="Quantity-button"
                              onClick={() => increseQuantity(list?.id)}
                            >
                              +
                            </button>
                          </div>
                          <p className="Total-price">
                            Total Price -{list?.price * list?.quantity} INR
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
