import React, { useState } from 'react';
import './LoginForm.css';
import { FaArrowRight } from "react-icons/fa6";

const NewPassword = ({ onResetPassword }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const checkPasswordStrength = (password) => {
    // Define regular expressions for password requirements
    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[$&+,:;=?@#|'<>.^*()%!-]/;

    // Check each requirement
    const hasLength = lengthRegex.test(password);
    const hasUppercase = uppercaseRegex.test(password);
    const hasLowercase = lowercaseRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const hasSpecialChar = specialCharRegex.test(password);

    // Calculate strength based on requirements met
    let score = 0;
    if (hasLength) score++;
    if (hasUppercase) score++;
    if (hasLowercase) score++;
    if (hasNumber) score++;
    if (hasSpecialChar) score++;

    // Determine strength level based on score
    if (score === 0) {
      return "Very Weak";
    } else if (score === 1) {
      return "Weak";
    } else if (score === 2) {
      return "Fair";
    } else if (score === 3) {
      return "Strong";
    } else if (score === 4) {
      return "Very Strong";
    } else {
      return "Valid Password";
    }
  };

  const passwordStrengthColor = () => {
    switch (passwordStrength) {
      case 'Very Weak':
        return 'red';
      case 'Weak':
        return 'orange';
      case 'Fair':
        return 'yellow';
      case 'Strong':
        return 'lightgreen';
      case 'Very Strong':
        return 'green';
      default:
        return 'inherit';
    }
  };

  const passwordStrengthWidth = () => {
    switch (passwordStrength) {
      case 'Very Weak':
        return '20%';
      case 'Weak':
        return '40%';
      case 'Fair':
        return '60%';
      case 'Strong':
        return '80%';
      case 'Very Strong':
        return '100%';
      default:
        return '0%';
    }
  };

  const handleResetPassword = () => {
    // Call the onResetPassword function passed from the parent component (App)
    onResetPassword();
  };

  return (
    <div className='Forgrt-Password'>
      <div>
        <img src="/image/logo 1.png" alt="Logo" className="Password-logo" />
      </div>
      <div className='New-box'>
        <h2>Set new password</h2>
        <p>Must be at least 8 characters</p>
        <div className="input-container">
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              className="password-input"
              value={password}
              onChange={handlePasswordChange}
              style={{ borderColor: passwordStrengthColor() }}
            />
            <div className="password-toggle" onClick={togglePasswordVisibility}>
              {showPassword ? "Hide" : "Show"}
            </div>
          </div>
          <div className="password-strength-indicator">
            <div
              className="password-strength-bar"
              style={{
                width: passwordStrengthWidth(),
                backgroundColor: passwordStrengthColor(),
              }}
            ></div>
            {passwordStrength && (
              <p>Password Strength: {passwordStrength}</p>
            )}
          </div>
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className="password-input"
            />
            <div className="password-toggle" onClick={togglePasswordVisibility}>
              {showPassword ? "Hide" : "Show"}
            </div>
          </div>
          <button className="reset-button" onClick={handleResetPassword}>
            Reset Password
          </button>
          <a href="#" className="cancel-link">
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
