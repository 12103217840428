import React, { useState } from 'react';
import './Timesheet.css'; 
import { IoShareOutline } from "react-icons/io5";
import { LiaAngleDownSolid } from "react-icons/lia";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom';

const Timesheet = ({ onLogout }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [description, setDescription] = useState('');

    const [showProjectDropdown, setShowProjectDropdown] = useState(false);
    const [showTaskDropdown, setShowTaskDropdown] = useState(false);

    const handleProfileClick = () => {
        setShowUserInfo(!showUserInfo);
    };

    const handleLogout = () => {
        onLogout();
    };

    const handleButton1Click = () => {
        setShowProjectDropdown(!showProjectDropdown);
        setShowTaskDropdown(false); // 
    };

    const handleButton2Click = () => {
        setShowTaskDropdown(!showTaskDropdown);
        setShowProjectDropdown(false); 
    };

    const handleButton3Click = () => {
        console.log("Button 3 clicked");
    };

    const handleText1Click = () => {
        console.log("Text 1 clicked");
    };

    const handleText2Click = () => {
        console.log("Text 2 clicked");
    };

    const handleCheckbox1Change = () => {
        setIsChecked1(true);
        setIsChecked2(false);
    };

    const handleCheckbox2Change = () => {
        setIsChecked1(false);
        setIsChecked2(true);
    };

    const handleCustomButtonClick = () => {
        console.log("Custom Button Clicked");
    };

    return (
        <div className='timesheet-body'>
            <div className="timesheet-heading">
                <h1>Timesheet</h1>
                <div className="profile-container" onClick={handleProfileClick}>
                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image" />
                    {showUserInfo && (
                        <div className="dropdown-menu">
                            <div className="user-info">
                            <Link to="/ProfilePage"> 
                                <div className="user-info-content">
                                    <img src=".\image\Group 15.png" alt="Profile" className="profile-image-small" />
                                    <div>
                                        <p className="user-name">Samraddhi Gupta</p>
                                        <p className="user-subtitle">Software Engineer</p>
                                    </div>
                                    </div>
                                    </Link>
                            </div>
                            <hr className="separator" />
                            <div className="logout" onClick={handleLogout}>
                                <IoShareOutline size={15} style={{ marginRight: '5px' }} />
                                <p>Log Out</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="combined-section">
                <div className="hours-section">
                    <div className="hours-info">
                        <p className="hours-heading">Total Hours: <span>07:41</span></p>
                        <p className="hours-heading">Filled Hours: <span>07:41</span></p>
                        <p className="hours-heading">Available Hours: 00:26</p>
                    </div>
                    <button className="add-more-button">+  Add More</button>
                </div>
                <div className="button-row">
                    <div className="button-wrapper">
                        <p>PROJECT<span>*</span></p>
                        <div className="dropdown">
                            <button className="more-button" onClick={handleButton1Click}>
                                Select Project
                                <LiaAngleDownSolid />
                            </button>
                            {showProjectDropdown && (
                                <div className="dropdown-content">
                                    <p>Project 1</p>
                                    <p>Project 2</p>
                                    <p>Project 3</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-wrapper">
                        <p>TASK<span>*</span></p>
                        <div className="dropdown">
                            <button className="more-button" onClick={handleButton2Click}>
                                Select Task
                                <LiaAngleDownSolid />
                            </button>
                            {showTaskDropdown && (
                                <div className="dropdown-content">
                                    <p>Task 1</p>
                                    <p>Task 2</p>
                                    <p>Task 3</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-wrapper">
                        <p>TIME ENTRY</p>
                        <button className="more-button" onClick={handleButton3Click}>02:00</button>
                    </div>
                    <div className="checkbox-section">
                        <p className="checkbox-heading">PROJECT BILLING TYPE<span>*</span></p>
                        <label className="checkbox-label">
                            <div className='flex'>
                            <input type="checkbox" checked={isChecked1} onChange={handleCheckbox1Change} />
                            <span className="checkbox-custom"></span>
                                <span>Billable </span>
                            </div>
                        </label>
                        <label className="checkbox-label">
                        <div className='flex'>
                            <input type="checkbox" checked={isChecked2} onChange={handleCheckbox2Change} />
                            <span className="checkbox-custom"></span>
                                <span>Non billable</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="editor-section">
                    <div className="editor-description-wrapper">
                        <div className="editor-container">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="editor-wrapper"
                                editorClassName="editor-content"
                                onEditorStateChange={setEditorState}
                            />
                        </div>
                        <button className="custom-button" onClick={handleCustomButtonClick}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timesheet;
